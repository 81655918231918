import React from "react"
import Button from "../../components/button"
import img from "../../images/image 14.webp"
import { Link } from "gatsby"
import Layout from "../../layout/layout"
import pdfLink from "../../../static/2025 English GST Catalog.pdf"
import pdfIcon from "../../images/pdf-file.svg"
// import Button from "../components/button"
import "../../styles/homestay.css"
const MasterOfArtsInMinisterialStudies = () => {
  return (
    <Layout
      title="Homestay"
      description=""
    >
      <div className="layout-container homestay-container" style={{marginBottom:"0px"}}>
        <div className="content">
        <div className="qualification-crumbs">
            Global University / Master of Arts in Ministerial Studies
          </div>
          <div className="InterculturalStudies section1" style={{display:"flex", gap:"80px", justifyContent:"center", alignItems:"start"}}>
            <div style={{width:"50%"}}>
              <h4>Master of Arts in Ministerial Studies</h4>
              <p className="p-lg">The Master of Arts in Ministerial Studies (45 credits) is tailored for those who desire to serve in one of the ministries of the church, especially that of pastor, evangelist or teacher. Focusing on pastoral care, leadership, and the practical aspects of ministry, students are prepared to lead congregations, offer spiritual guidance, and make impactful changes in their communities.
              </p>
              <p className="p-lg">Choices for the Ministerial Studies program are:<br></br>
                  Broad Field Plan<br></br>
                  Educational Concentration<br></br>
                  Intercultural Studies Concentration<br></br>
                  Leadership Concentration
              </p>
              <p className="p-lg" style={{color:"#00c38d", fontWeight: "bold"}}>
              Core Courses for MA degree: 24 credits
              </p>
              <p></p>
              <h4 style={{color:"#00c38d", fontWeight: "bold"}}>Course Credits</h4>
              <table className="table-container">
              <thead>
                <tr>
                  <th>Reference</th>
                  <th>Title</th>
                  <th>Level</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>BIB 5033</td>
                  <td>The Bible as Literature: Theory and Methodology</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>BIB 6293</td>
                  <td>Hermeneutics: God’s Message and Its Meaning</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>BNT 6043</td>
                  <td>Pneumatology in Lucan Literature</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>BNT 6203</td>
                  <td>Backgrounds to the New Testament: Cultural and Historical Contexts</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>LDR 5013</td>
                  <td>Foundations for Christian Leadership</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>MIS 5013</td>
                  <td>The Biblical Theology of Missions</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>RES 5023</td>
                  <td>Graduate Research and Writing: Principles and Practice</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>THE 6093</td>
                  <td>Theology of the Holy Spirit in the Old Testament</td>
                  <td>3</td>
                </tr>
              </tbody>
            </table>
            <div style={{marginTop:"30px"}}>
              <Link to="/contact">
                <Button>Contact us</Button>
              </Link>
            </div>
              </div>
                <div style={{width:"50%"}}>
                  <img src={img} alt="img" style={{marginTop:"40px",width:"100%"}}/>
                  <div style={{display:"flex", gap:"15px", justifyContent:"center", alignItems:"center", paddingTop:"20px"}}>
                  <a href={pdfLink} target="blank" ><img src={pdfIcon} alt="img" style={{margin:"0px", width:"40px"}}/></a>
                  <a href={pdfLink} target="blank" ><p className="p-md" style={{fontSize:"15px",textDecoration:"underline"}}>2025 Global University, Undergraduate School of Bible and Theology Catalog</p></a>
                </div>
                </div>
              </div>
        </div>
      </div>
      
    </Layout>
  )
}

export default MasterOfArtsInMinisterialStudies
